<template>
  <div class="mortgage-result-broker signed">
    <div class="mortgage-parameters signed">
      <img
        :src="application.bank.logo_square"
        loading="lazy"
        alt=""
        class="bank-logo"
      >
      <div
        class="bold wrap100px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      >
        {{ getTotalAmountBasedOnCorrection() | dotTheAmount }} EUR
      </div>
      <div
        class="bold wrap50px"
      >
        {{ application.sadzba_pozadovana | toFloor(2) }}<span class="upper-index">%</span>
      </div>
      <div
        class="bold wrap100px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      > {{ application.splatka | dotTheAmount }} <span class="upper-index">EUR</span>
      </div>
      <div
        class="bold wrap50px"
        :class="{'red-text-custom' : application.backEndLimitCorrected}"
      > {{  application.poplatok_za_poskytnutie | dotTheAmount }} <span class="upper-index">EUR</span>
      </div>
      <div class="bold wrap50px">{{ application.splatnost }}
        <span
          class="upper-index">R</span></div>
      <div class="bold wrap100px">{{ application.popl_za_vedenie_uctu }} EUR<span
        class="upper-index">/m</span></div>
      <div class="bold wrap50px">{{ application.fix }}</div>
    </div>
    <div class="div-block-144">
      <div class="link-icon-text"
           @click="removeApplication">
      <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f1b_icons8-trash.svg" alt="">
        zmazať
      </div>
      <div v-if="application.stage === 'podpisana'" class="link-icon-text">
      <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4a_icons8-clock.svg" alt="">
        na schvaľovaní
      </div>
      <div v-if="application.stage === 'rozpracovana' || application.stage === 'nachystana'"  class="link-icon-text">
        <application-generator-handler :rules="['priority']" :image="image" :application="application"/>
        generovať
      </div>
      <div v-if="application.stage === 'rozpracovana' || application.stage === 'nachystana'" class="link-icon-text" @click="setApplicationAsSigned">
        <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f50_icons8-done.svg" alt="">
        podpisaná
      </div>
      <document-cloud-handler :leadingText="true" :type="2" :cloud-meta="applicationCloud" :rules="['priority']" force-file-replace icon-button-sign/>
      <!-- <div class="link-icon-text" @click="openHelperPopup">
        <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f4d_icons8-ereader.svg" alt="">
        ako podať?
      </div> -->
    </div>
    <div class="uploaded-files-wrapper">
      <div
        class="link-block-7 w-inline-block justify-content-between"
        v-for="(file, index) in applicationCloud._.clouds[0].files"
        :key="index"
        v-if="file.type?.code === 'podpisana_ziadost' || file.type?.code === 'vygenerovana_ziadost'"
      >
        <div class="d-flex flex-row mr-3">
          <div class="small">{{ file.name }}</div>
        </div>
        <a
          :href="file.file"
          target="_blank"
          class="text-cta pointer"
          style="text-decoration: none"
        >
          STIAHNUŤ
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationMissingInfoHandler
  from "../../../../shared/PopupHandler/Popups/ApplicationMissingInfo/ApplicationMissingInfoHandler";
import ApplicationGeneratorHandler
  from "../../../../shared/PopupHandler/Popups/ApplicationGenerator/ApplicationGeneratorHandler";
import imagesConvert from "../../../../../mixins/imagesConvert";
import DocumentCloudHandler from "@/components/shared/PopupHandler/Popups/DocumentCloud/DocumentCloudHandler.vue";
import {mapGetters} from "vuex";

export default {
  name: "MortgageApplicationSignItem",
  components: {
    DocumentCloudHandler,
    ApplicationGeneratorHandler,
    ApplicationMissingInfoHandler
  },
  props: {
    application: Object,
  },
  mixins: [
    imagesConvert,
  ],
  computed: {
    ...mapGetters({
      cloudsGetter: "mortgageDetailCloud/getClouds",
    }),
  },
  watch: {
    cloudsGetter(newClouds) {
      for (const newCloud of newClouds) {
        if (newCloud.cloudId !== this.application.cloud) continue;
        this.applicationCloud = newCloud.cloudMeta;
        break;
      }
    },
  },
  created() {
    this.applicationCloud = (this.$store.getters["mortgageDetailCloud/getCloudByCloudId"](this.application.cloud)).cloudMeta;
    this.applicationCloud._.clouds[0].applicationId = this.application.id;
  },
  methods: {
    async setApplicationAsSigned() {
      if (this.application.stage === "rozpracovana") {
        await this.$store.dispatch("mortgageDetailState/setMortgageApplicationStage", {id: this.application.id, newStage: "podpisana"});
        this.$store.commit("appState/setSuccessMessage", {
          text: "Žiadosť bola úspešne zmenená na podpísanú",
          type: "success",
        })
      }
    },
    openHelperPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "ApplicationHelpPopUp",
        rules: ['single'],
      })
    },
    removeApplication () {
      const removedApplication = {
        ...this.application,
        stage_old: this.application.stage,
        stage: "zrusena",
      }
      this.$store.dispatch("mortgageDetailState/removeApplication", {removedApplication});
    },
    getTotalAmountBasedOnCorrection() {
      if (this.application.backEndLimitCorrected) {
        return this.application.correctedAmount;
      }
      return this.application.suma_nadobudnutie + this.application.suma_refinancovanie + this.application.suma_bezucel;
    },
  },
  data: () => ({
    applicationCloud: null,
    image: null,
  }),
}
</script>

<style scoped>
</style>
